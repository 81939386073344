import React, { useEffect, useState, useRef } from 'react';
import {
    Button, Drawer, Slide, Typography, IconButton, Container, Box, Grid, CircularProgress, FormControlLabel, Checkbox
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/system';
import CustomInput from "./components/CustomInput";
import CustomTextarea from "./components/CustomTextarea";
import CourseCoverSelector from './components/CourseCoverSelector';
import { translate } from "../translations";
import { setSelectedCourse, setSelectedStep } from "../store";
import { processImageWithExif } from './exifHandler';
import SubscriptionDialog from "../SubscriptionDialog";
import useWebApp from "../twa/useWebApp";

const StyledLabel = styled('label')(({ themeParams }) => ({
    fontSize: '12px',
    color: themeParams.section_header_text_color,
    marginBottom: '5px',
    textTransform: 'uppercase',
}));

const StyledCheckbox = styled(Checkbox)(({ themeParams }) => ({
    color: `${themeParams.section_header_text_color} !important`,
    '&.Mui-checked': {
        color: `${themeParams.button_color} !important`,
    },
}));

const StepEditModal = ({ themeParams, open, onClose, language, step }) => {
    const dispatch = useDispatch();
    const course = useSelector(state => state.selectedCourse);
    const accessToken = useSelector(state => state.accessToken);
    const [isSubscriptionDialogOpen, setSubscriptionDialogOpen] = useState(false);

    const [customCoverImage, setCustomCoverImage] = useState(step.payload.custom_cover_image || false);
    const [payload, setPayload] = useState({
        name: step.name || '',
        text: step.text || '',
        status: step.status || 'draft',
        preset: step.payload.cover_preset || 'bold',
        amount: step.payload.amount || null,
        file: null,
    });
    const [originalPayload, setOriginalPayload] = useState({}); // Для хранения исходных данных
    const [isPublish, setIsPublish] = useState(step.status === 'public');
    const [loading, setLoading] = useState(false);
    const [drawerHeight, setDrawerHeight] = useState(75);
    const WebApp = useWebApp();

    const getDefaultHeight = (type) => {
        let drawerHeight = 75;

        switch (type) {
            case 'text':
                drawerHeight = 55;
            case 'invoice':
                drawerHeight = 85;
            case 'image':
                drawerHeight = 75;
        }

        if (WebApp) {
            if (WebApp.platform.includes("android") || WebApp.platform.includes("ios")) {
                drawerHeight = drawerHeight - 15;
            }
        }

        return drawerHeight;
    };

    useEffect(() => {
        setDrawerHeight(getDefaultHeight(step.type));
    }, [step.type]);

    useEffect(() => {
        setPayload({
            name: step.name || '',
            text: step.text || '',
            status: step.status || 'draft',
            preset: step.payload.cover_preset || 'bold',
            amount: step.payload.amount || null,
            file: null,
        });

        setCustomCoverImage(step.payload.custom_cover_image || false);
        setIsPublish(step.status === 'public');
        setOriginalPayload({
            name: step.name || '',
            text: step.text || '',
            preset: step.payload.cover_preset || 'bold',
            amount: step.payload.amount || null,
            custom_cover_image: step.payload.custom_cover_image || false,
        });
    }, [step]);

    const handleFocus = (height, ref) => {
        if (drawerHeight !== height) {
            setDrawerHeight(height);
        }
        if (ref && ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    const handleBlur = () => {
        setDrawerHeight(getDefaultHeight(step.type));
    };

    const handlerCoverChange = (value) => {
        handleDataChange('custom_cover_image', value);
        setCustomCoverImage(value);
    };

    const handlePublishChange = (e) => {
        setIsPublish(e.target.checked);
    };

    const handleDataChange = (key, value) => {
        setPayload({ ...payload, [key]: value });
    };

    const uploadFile = async () => {
        try {
            // Обрабатываем изображение и учитываем EXIF-данные
            const processedFile = await processImageWithExif(payload.file);

            const formData = new FormData();
            formData.append('file', processedFile, payload.file.name); // Используем исправленный файл

            const response = await axios.post('/v1/storage/upload/timage', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });

            if (response.status === 200) {
                return response.data.data.id;
            } else {
                console.error('Ошибка при загрузке файла:', response);
                return null;
            }
        } catch (error) {
            if (error.response?.data?.type === 'plan_limit_exceeded') {
                setSubscriptionDialogOpen(true);
            }

            console.error('Ошибка при обработке или загрузке файла:', error);
            return null;
        }
    };

    const saveData = async () => {
        setLoading(true);
        try {
            let updatedPayload = {};

            // Проверяем измененные поля
            if (payload.name !== originalPayload.name) {
                updatedPayload.name = payload.name;
            }
            if (payload.text !== originalPayload.text) {
                updatedPayload.text = payload.text;
            }
            if (payload.amount !== originalPayload.amount) {
                updatedPayload.amount = payload.amount;
            }
            if (payload.preset !== originalPayload.preset && !payload.file) {
                updatedPayload.cover_preset = payload.preset;
            }
            if (customCoverImage !== originalPayload.custom_cover_image) {
                updatedPayload.custom_cover_image = customCoverImage;
            }

            updatedPayload.status = isPublish ? "public" : "draft";

            // Проверка на загрузку файла
            if (payload.file) {
                const imageId = await uploadFile();
                if (imageId) {
                    updatedPayload.image_id = imageId;
                }
            }

            // Если есть изменения, отправляем запрос на обновление
            if (Object.keys(updatedPayload).length > 0) {
                const response = await axios.put(`/v1/steps/${step.id}`, updatedPayload, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (response.data.status === 200) {
                    const updatedSteps = course.steps.map(s => s.id === response.data.data.id ? response.data.data : s);
                    const updatedCourse = {
                        ...course,
                        steps: updatedSteps,
                    };

                    dispatch(setSelectedCourse(updatedCourse));
                    dispatch(setSelectedStep({ id: response.data.data.id, entity: response.data.data, type: 'step' }));
                    onClose();
                }
            } else {
                console.log("Никаких изменений не обнаружено.");
            }
        } catch (error) {
            if (error.response?.data?.type === 'plan_limit_exceeded') {
                setSubscriptionDialogOpen(true);
            }
            
            console.error("Ошибка при сохранении шага:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Drawer
            anchor="bottom"
            open={open}
            onClose={onClose}
            PaperProps={{
                style: {
                    borderTopLeftRadius: '16px',
                    borderTopRightRadius: '16px',
                    padding: '16px',
                    backgroundColor: themeParams.section_bg_color,
                    filter: 'none',
                    mixBlendMode: 'normal',
                    backgroundImage: 'none',
                    overflowY: 'auto',
                    height: drawerHeight + 'vh',
                    transition: 'height 0.3s ease',

                    maxWidth: '700px',
                    margin: '0 auto',
                },
            }}
        >
            <Slide direction="up" in={open}>
                <div style={{ overflowY: 'auto' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '8px' }}>
                        <Typography>{translate(language, 'edit_step')}</Typography>
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>

                    <Container maxWidth="sm">
                        <CustomInput
                            label={translate(language, 'title')}
                            value={payload.name}
                            onChange={(e) => handleDataChange('name', e.target.value)}
                            onFocus={() => handleFocus(step.type === 'text' ? 70 : 90)}
                            onBlur={handleBlur}
                            themeParams={themeParams}
                        />

                        <CustomTextarea
                            label={translate(language, 'text')}
                            value={payload.text}
                            onChange={(e) => handleDataChange('text', e.target.value)}
                            onFocus={() => handleFocus(step.type === 'text' ? 90 : 90)}
                            onBlur={handleBlur}
                            themeParams={themeParams}
                        />

                        {step.type === 'invoice' ? (
                            <CustomInput
                                label={translate(language, 'amount')}
                                value={payload.amount}
                                onChange={(e) => handleDataChange('amount', e.target.value)}
                                onFocus={() => handleFocus(100)}
                                onBlur={handleBlur}
                                themeParams={themeParams}
                            />
                        ) : ''}

                        {step.type === 'invoice' || step.type === 'image' ? (
                            <CourseCoverSelector
                                setCustomCoverImage={handlerCoverChange}
                                customCoverImage={customCoverImage}
                                file={payload.file}
                                handleFileChange={(file) => handleDataChange('file', file)}
                                preset={payload.preset}
                                setPreset={(preset) => handleDataChange('preset', preset)}
                                themeParams={themeParams}
                                language={language}
                            />
                        ) : ''}

                        <FormControlLabel
                            control={<StyledCheckbox checked={isPublish} onChange={handlePublishChange}
                                                     themeParams={themeParams}/>}
                            label={<StyledLabel
                                themeParams={themeParams} onClick={() => setIsPublish(!isPublish)}>{translate(language, 'publish_step')}</StyledLabel>}
                        />

                        <div style={{
                            position: 'sticky',
                            bottom: 0,
                            backgroundColor: themeParams.section_bg_color,
                            padding: '10px 0'
                        }}>
                            <Grid container justifyContent="flex-end">
                                <Button
                                    sx={{
                                        width: '100%',
                                        backgroundColor: themeParams.button_color,
                                        color: themeParams.button_text_color,
                                        padding: '10px',
                                        borderRadius: '12px',
                                        '&:hover': {
                                            backgroundColor: themeParams.button_color
                                        },
                                        '&:active': {
                                            backgroundColor: themeParams.button_color
                                        }
                                    }}
                                    onClick={saveData}
                                >
                                    {loading ? (
                                        <CircularProgress size={24} sx={{color: themeParams.button_text_color}}/>
                                    ) : (
                                        translate(language, 'save')
                                    )}
                                </Button>
                            </Grid>
                        </div>
                    </Container>
                </div>
            </Slide>
            <SubscriptionDialog
                themeParams={themeParams}
                open={isSubscriptionDialogOpen}
                onClose={() => setSubscriptionDialogOpen(false)}
                language={language}
            />
        </Drawer>
    );
};

export default StepEditModal;