import React, { useState, useEffect, memo } from 'react';
import {
    Box,
    Typography,
    Avatar,
    Divider,
    Pagination,
    Skeleton,
    Tabs,
    Tab,
} from '@mui/material';
import axios from 'axios';
import { translate } from './translations';
import { useSelector } from 'react-redux';

const Transactions = ({ themeParams, language, courseID }) => {
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const token = useSelector((state) => state.accessToken);

    const pageSize = 10;

    const fetchTransactions = async () => {
        setLoading(true);

        try {
            // Определяем параметр status в зависимости от текущего tabIndex
            const status = tabIndex === 1 ? 'paid' : tabIndex === 2 ? 'refund' : undefined;

            const response = await axios.get(`/v1/payments`, {
                params: {
                    page,
                    size: pageSize,
                    course_id: courseID,
                    ...(status && { status }), // Добавляем status только если он определен
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setTransactions(response.data.data || []);
            setTotalPages(response.data.pagination.total_pages || 1);
        } catch (error) {
            console.error('Ошибка при загрузке транзакций:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (token != null) {
            fetchTransactions();
        }
    }, [page, courseID, tabIndex]); // Добавляем tabIndex в зависимости

    const renderCurrencyIcon = () => (
        <img
            src="https://coob.app/assets/icons/telegram_star.webp"
            alt="Star"
            style={{ width: 16, height: 16, marginLeft: 4 }}
        />
    );

    const renderSkeleton = () =>
        Array.from({ length: pageSize }).map((_, index) => (
            <React.Fragment key={index}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderRadius: '10px',
                        padding: 1,
                        mb: 1,
                        backgroundColor: themeParams.section_bg_color,
                    }}
                >
                    <Box sx={{ flexGrow: 1 }}>
                        <Skeleton variant="text" width="40%" height={20} />
                        <Skeleton variant="text" width="60%" height={15} sx={{ mt: 1 }} />
                        <Skeleton variant="text" width="30%" height={15} sx={{ mt: 1 }} />
                    </Box>
                    <Skeleton variant="circular" width={50} height={50} />
                </Box>
                <Divider />
            </React.Fragment>
        ));

    return (
        <Box sx={{ padding: 2, minHeight: '100vh' }}>
            {/* Tabs */}
            <Typography
                variant="body2"
                sx={{
                    ml: 1,
                    color: themeParams.section_header_text_color,
                    textTransform: 'uppercase',
                    mb: 2,
                }}
            >
                {translate(language, 'transactions')}
            </Typography>
            <Tabs
                value={tabIndex}
                onChange={(e, value) => {
                    setTabIndex(value);
                    setPage(1);
                }}
                variant="fullWidth"
                textColor="inherit"
                TabIndicatorProps={{
                    style: { backgroundColor: themeParams.button_color },
                }}
                sx={{
                    backgroundColor: themeParams.section_bg_color,
                    borderRadius: '10px',
                    marginBottom: 2,
                }}
            >
                <Tab label={translate(language, 'all')} />
                <Tab label={translate(language, 'incoming')} />
                <Tab label={translate(language, 'refund')} />
            </Tabs>

            {/* Transaction List */}
            <Box
                sx={{
                    backgroundColor: themeParams.section_bg_color,
                    borderRadius: '20px',
                    padding: 2,
                }}
            >
                {loading ? (
                    renderSkeleton()
                ) : transactions.length > 0 ? (
                    transactions.map((transaction, index) => (
                        <React.Fragment key={transaction.id}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    borderRadius: '10px',
                                    padding: 1,
                                    mb: 1,
                                    cursor: 'pointer',
                                }}
                            >
                                <Box>
                                    <Typography variant="body1">
                                        {transaction.step?.title || translate(language, 'no_step_title')}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: '#888' }}>
                                        {transaction.student.first_name} {transaction.student.last_name}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: '#888' }}>
                                        {transaction.created_at}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography
                                        variant="body1"
                                        sx={{ color: transaction.status === 'refund' ? '#ff5252' : '#05bc24' }}
                                    >
                                        {transaction.status === 'refund'
                                            ? `-${transaction.refund_amount}`
                                            : `+${transaction.amount}`}
                                    </Typography>
                                    {renderCurrencyIcon()}
                                    <Avatar
                                        src={transaction.student.avatar}
                                        alt={transaction.student.first_name || 'User'}
                                        sx={{ width: 50, height: 50, marginLeft: 2 }}
                                    />
                                </Box>
                            </Box>
                            {index !== transactions.length - 1 && <Divider />}
                        </React.Fragment>
                    ))
                ) : (
                    <Typography variant="body2" color="textSecondary" align="center" marginY={2}>
                        {translate(language, 'no_transactions')}
                    </Typography>
                )}
            </Box>

            {/* Pagination */}
            {totalPages > 1 && (
                <Pagination
                    count={totalPages}
                    page={page}
                    onChange={(event, value) => setPage(value)}
                    shape="rounded"
                    sx={{
                        marginTop: 4,
                        display: 'flex',
                        justifyContent: 'center',
                        '& .MuiPaginationItem-root': {
                            color: themeParams.button_text_color,
                            fontSize: '18px',
                        },
                        '& .Mui-selected': {
                            backgroundColor: themeParams.accent_text_color,
                            color: '#fff',
                        },
                    }}
                />
            )}
        </Box>
    );
};

export default memo(Transactions);