import React, {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import {
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    Avatar,
    IconButton,
    Container,
    Typography,
    Skeleton,
    Pagination,
    Box,
    Tabs,
    Slide,
    Tab,
    Divider,
    CircularProgress,
    InputAdornment,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ClearIcon from '@mui/icons-material/Clear';
import {useSelector, useDispatch} from 'react-redux';
import {setPlugins} from '../../store';
import CustomInput from "../components/CustomInput";
import {translate} from "../../translations";
import CloseIcon from "@mui/icons-material/Close";
import useWebApp from "../../twa/useWebApp";

const PluginManagerMobile = ({themeParams, language, open, onClose, course_id}) => {
    const [allPlugins, setAllPlugins] = useState([]);
    const [installedPlugins, setInstalledPlugins] = useState([]);
    const [myPlugins, setMyPlugins] = useState([]);
    const [loading, setLoading] = useState(false);
    const [actionLoading, setActionLoading] = useState({});
    const [selectedPlugin, setSelectedPlugin] = useState(null);
    const [page, setPage] = useState(1);
    const [paginationData, setPaginationData] = useState({
        marketplace: {total_pages: 1},
        installed: {total_pages: 1},
        myPlugins: {total_pages: 1},
    });
    const [tabIndex, setTabIndex] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const dispatch = useDispatch();
    const plugins = useSelector(state => state.plugins);
    const [drawerHeight, setDrawerHeight] = useState('90vh');
    const accessToken = useSelector(state => state.accessToken);
    const pageSize = 20;

    let WebApp = useWebApp();

    useEffect(() => {
        if (!open) {
            // Сброс состояния при закрытии Drawer
            setSelectedPlugin(null);
            setPage(1);
            setSearchQuery('');
            setTabIndex(0);
        }
    }, [open]);

    useEffect(() => {
        if (open) {
            fetchPlugins();
        }
    }, [open, tabIndex, page, searchQuery]);

    useEffect(() => {
        if (selectedPlugin) {
            WebApp.MainButton.show();
            WebApp.MainButton.setParams({
                text: translate(language, 'back'),
                color: themeParams.bg_color,
                text_color: themeParams.subtitle_text_color,
                is_active: true,
            });
            WebApp.MainButton.onClick(() => {
                setSelectedPlugin(null);
                WebApp.MainButton.hideProgress();
            });
        } else {
            WebApp.MainButton.hideProgress();
            WebApp.MainButton.hide();
            WebApp.MainButton.offClick();
        }

        return () => {
            WebApp.MainButton.hideProgress();
            WebApp.MainButton.offClick();
        };
    }, [selectedPlugin]);

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
        setPage(1); // Reset pagination on tab change
        setSelectedPlugin(null);
        setSearchQuery("");
    };

    const fetchPlugins = async () => {
        setLoading(true);
        try {
            let response;
            if (tabIndex === 0) {
                response = await axios.get(`/v1/plugins?marketplace=true&view_course_id=${course_id}`, {
                    params: {size: pageSize, page, search: searchQuery},
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                });
                setAllPlugins(response.data.data);
                setPaginationData((prev) => ({...prev, marketplace: response.data.pagination}));
            } else if (tabIndex === 1) {
                response = await axios.get(`/v1/plugins?course_id=${course_id}`, {
                    params: {size: pageSize, page, search: searchQuery},
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                });
                setInstalledPlugins(response.data.data);
                setPaginationData((prev) => ({...prev, installed: response.data.pagination}));
            } else if (tabIndex === 2) {
                response = await axios.get(`/v1/plugins?view_course_id=${course_id}&my=true`, {
                    params: {size: pageSize, page, search: searchQuery},
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                });
                setMyPlugins(response.data.data);
                setPaginationData((prev) => ({...prev, myPlugins: response.data.pagination}));
            }
        } catch (error) {
            console.error('Error fetching plugins:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleAddPlugin = async (pluginId, event) => {
        event.stopPropagation();
        setActionLoading((prev) => ({...prev, [pluginId]: true}));
        try {
            const response = await axios.post(`/v1/plugins/${pluginId}/add`, {course_id}, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
            const newPlugin = response.data.data;
            dispatch(setPlugins([...plugins, newPlugin]));

            setAllPlugins((prev) => prev.map((plugin) => (
                plugin.id === newPlugin.id ? {...plugin, is_installed: true} : plugin
            )));
            setInstalledPlugins((prev) => [...prev, {...newPlugin, is_installed: true}]);
        } catch (error) {
            console.error('Error adding plugin:', error);
        } finally {
            setActionLoading((prev) => ({...prev, [pluginId]: false}));
        }
    };

    const handleRemovePlugin = async (pluginId, event) => {
        event.stopPropagation();
        setActionLoading((prev) => ({...prev, [pluginId]: true}));
        try {
            await axios.post(`/v1/plugins/${pluginId}/remove`, {course_id}, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
            dispatch(setPlugins(plugins.filter((plugin) => plugin.id !== pluginId)));
            setInstalledPlugins((prev) => prev.filter((plugin) => plugin.id !== pluginId));
            setAllPlugins((prev) => prev.map((plugin) => (
                plugin.id === pluginId ? {...plugin, is_installed: false} : plugin
            )));
        } catch (error) {
            console.error('Error removing plugin:', error);
        } finally {
            setActionLoading((prev) => ({...prev, [pluginId]: false}));
        }
    };

    const renderPluginList = (pluginsList, index) => (
        <List style={{ width: '100%' }}>
            {index === 0 && (
                <>
                    <CustomInput
                        themeParams={themeParams}
                        size="small"
                        placeholder={translate(language, 'search_components')}
                        value={searchQuery}
                        onChange={(event) => {
                            setSearchQuery(event.target.value);
                            setSelectedPlugin(null);
                            setPage(1);
                        }}
                        style={{ marginBottom: '16px', width: '100%' }}
                        InputProps={{
                            endAdornment: searchQuery && (
                                <InputAdornment position="end">
                                    <IconButton size="small" onClick={() => setSearchQuery('')}>
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Divider
                        sx={{
                            marginTop: '16px',
                            marginBottom: '16px',
                        }}
                    />
                </>
            )}
            {loading ? (
                [...Array(pageSize)].map((_, index) => (
                    <ListItem key={index}>
                        <ListItemIcon>
                            <Skeleton variant="circular" width={40} height={40} />
                        </ListItemIcon>
                        <div style={{ width: '100%' }}>
                            <Skeleton width="60%" height={20} />
                            <Skeleton width="50%" height={15} />
                        </div>
                    </ListItem>
                ))
            ) : pluginsList?.length === 0 && index === 2 ? (
                <div style={{textAlign: 'center', marginTop: '20px'}}>
                    <Typography variant="h6" style={{marginBottom: '10px'}}>
                        {translate(language, 'develop_plugin_title')}
                    </Typography>
                    <Typography variant="body2" style={{marginBottom: '20px'}}>
                        {translate(language, 'develop_plugin_description')}
                    </Typography>
                    <a
                        href="https://docs.coob.app/plugin/plugin-quickstart-guide"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            textDecoration: 'none',
                            color: themeParams.accent_text_color || '#007BFF',
                            fontWeight: 'bold',
                        }}
                    >
                        {translate(language, 'develop_plugin_link_text')}
                    </a>
                </div>
            ) : (
                pluginsList?.map((plugin) => (
                    <ListItem
                        key={plugin.id}
                        button
                        onClick={() => setSelectedPlugin(plugin)}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            backgroundColor:
                                selectedPlugin && selectedPlugin.id === plugin.id
                                    ? '#f0f8ff'
                                    : 'transparent',
                            borderRadius: '4px',
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                            <ListItemIcon>
                                <Avatar
                                    src={plugin.icon}
                                    alt={plugin.name}
                                    sx={{
                                        backgroundColor: '#fff',
                                    }}
                                >
                                    {plugin.name.charAt(0)}
                                </Avatar>
                            </ListItemIcon>
                            <div>
                                <Typography variant="inherit" style={{ fontWeight: 'bold' }}>
                                    {translate(language, plugin.name)}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {plugin.short_description}
                                </Typography>
                            </div>
                        </div>
                        <div>
                            {actionLoading[plugin.id] ? (
                                <CircularProgress size={24} />
                            ) : plugin.is_installed ? (
                                <IconButton onClick={(event) => handleRemovePlugin(plugin.id, event)}>
                                    <RemoveIcon />
                                </IconButton>
                            ) : (
                                <IconButton onClick={(event) => handleAddPlugin(plugin.id, event)}>
                                    <AddIcon />
                                </IconButton>
                            )}
                        </div>
                    </ListItem>
                ))
            )}
        </List>
    );


    return (
        <Drawer
            anchor="bottom"
            open={open}
            onClose={onClose}
            PaperProps={{
                style: {
                    borderTopLeftRadius: '16px',
                    borderTopRightRadius: '16px',
                    padding: '16px',
                    backgroundColor: themeParams.section_bg_color,
                    filter: 'none',
                    mixBlendMode: 'normal',
                    backgroundImage: 'none',
                    height: drawerHeight,
                    overflowY: 'auto',
                    transition: 'height 0.3s ease',

                    maxWidth: '700px',
                    margin: '0 auto',
                },
            }}
        >
            <Slide direction="up" in={open} mountOnEnter unmountOnExit>
                <div style={{overflowY: 'auto'}}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        borderBottom: `1px solid ${themeParams.section_separator_color}`,
                        paddingBottom: '8px'
                    }}>
                        <Typography sx={{
                            fontSize: '14px',
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                        }}>{translate(language, 'components_manager')}</Typography>
                        <IconButton onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                    <Container maxWidth="sm" style={{padding: 10}}>
                        {selectedPlugin ? (
                            <Box>
                                <Typography variant="h6" gutterBottom>
                                    {selectedPlugin.name}
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    color="text.secondary"
                                    sx={{ marginBottom: '8px' }}
                                >
                                    {`Category: ${selectedPlugin.category || 'Unknown'}`}
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    color="text.secondary"
                                    sx={{ marginBottom: '16px' }}
                                >
                                    {`Author: ${selectedPlugin.author || 'Anonymous'}`}
                                </Typography>
                                <div dangerouslySetInnerHTML={{ __html: selectedPlugin.description }}></div>
                            </Box>
                        ) : (
                            <>
                                <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
                                    <Tabs
                                        value={tabIndex}
                                        onChange={handleTabChange}
                                        variant="scrollable" // Включает горизонтальную прокрутку
                                        scrollButtons="auto" // Кнопки прокрутки появляются автоматически
                                        sx={{
                                            '& .MuiTabs-indicator': {
                                                backgroundColor: themeParams.accent_text_color || '#007BFF', // Цвет индикатора активной вкладки
                                                height: '4px', // Толщина индикатора
                                            },
                                            '& .MuiTab-root': {
                                                color: themeParams.button_text_color || '#555', // Цвет текста неактивных вкладок
                                                textTransform: 'none', // Убираем капс
                                                fontWeight: '500', // Средняя жирность текста
                                                fontSize: '1rem', // Размер текста
                                                minWidth: '120px', // Минимальная ширина вкладки
                                                '&:hover': {
                                                    color: themeParams.accent_text_color || '#007BFF', // Цвет текста при наведении
                                                    opacity: 1,
                                                },
                                                '&.Mui-selected': {
                                                    color: themeParams.accent_text_color || '#007BFF', // Цвет текста активной вкладки
                                                    fontWeight: '600', // Жирность активной вкладки
                                                },
                                            },
                                        }}
                                    >
                                        <Tab label={translate(language, 'marketplace')} />
                                        <Tab label={translate(language, 'installed')} />
                                        <Tab label={translate(language, 'my_components')} />
                                    </Tabs>

                                </Box>
                                {tabIndex === 0 && renderPluginList(allPlugins, 0)}
                                {tabIndex === 1 && renderPluginList(installedPlugins, 1)}
                                {tabIndex === 2 && renderPluginList(myPlugins, 2)}
                                <Pagination
                                    count={paginationData[tabIndex === 0 ? 'marketplace' : tabIndex === 1 ? 'installed' : 'myPlugins'].total_pages}
                                    page={page}
                                    onChange={(event, value) => {
                                        setPage(value);
                                        setSelectedPlugin(null);
                                    }}
                                    shape="rounded"
                                    sx={{
                                        marginTop: '16px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        '& .MuiPaginationItem-root': {
                                            color: `${themeParams.button_text_color}`, // Цвет текста кнопок
                                            fontSize: '23px', // Базовый размер шрифта
                                            '@media (max-width: 600px)': {
                                                fontSize: '23px', // Увеличенный размер шрифта для мобильных устройств
                                            },
                                        },
                                        '& .Mui-selected': {
                                            backgroundColor: `${themeParams.accent_text_color}`, // Цвет для активного элемента
                                            color: '#fff', // Белый текст для контраста
                                        },
                                        '& .MuiPaginationItem-ellipsis': {
                                            fontSize: '23px', // Увеличенный размер для троеточий
                                        },
                                    }}
                                />

                            </>
                        )}
                    </Container>
                </div>
            </Slide>
        </Drawer>
    );
};

export default PluginManagerMobile;