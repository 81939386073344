import React, { useState, useEffect } from 'react';
import { Select, MenuItem } from '@mui/material';
import { styled } from '@mui/system';

const StyledSelectContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
});

const StyledLabel = styled('label')(({ themeParams }) => ({
    fontSize: '12px',
    color: themeParams.section_header_text_color,
    marginBottom: '5px',
    textTransform: 'uppercase',
}));

const StyledSelect = styled(Select)(({ themeParams }) => ({
    width: '100%',
    fontSize: '16px',
    color: themeParams.text_color,
    borderRadius: '12px',
    marginBottom: '10px',
    border: `1px solid ${themeParams.section_header_text_color}`,
    backgroundColor: themeParams.secondary_bg_color,
    outline: 'none',
    '& .MuiSelect-icon': {
        color: themeParams.text_color,
    },
    '&:focus': {
        border: `2px solid ${themeParams.button_color}`,
    },
}));

function PresetSelector({ label, value, onChange, className, style, itemClassName, themeParams }) {
    const [data, setData] = useState({ presets: {}, gradients: {} });

    useEffect(() => {
        fetch('https://api.coob.app/public/cj')
            .then((response) => response.json())
            .then((json) => setData(json))
            .catch((error) => console.error('Error fetching presets:', error));
    }, []);

    return (
        <StyledSelectContainer>
            <StyledLabel themeParams={themeParams}>{label}</StyledLabel>
            <StyledSelect
                labelId="preset-selector-label"
                id="preset-selector"
                value={value}
                onChange={onChange}
                label={label}
                themeParams={themeParams}
                MenuProps={{ disableScrollLock: true }}
            >
                {Object.entries(data.presets).map(([key, preset]) => {
                    const gradient = data.gradients[preset.backgroundGradientTemplate] || {};
                    return (
                        <MenuItem key={key} value={key} className={itemClassName}>
                            <span style={{
                                display: 'inline-block',
                                width: '20px',
                                height: '20px',
                                marginRight: '10px',
                                border: '1px solid #000',
                                backgroundColor: gradient.backgroundColor || preset.fontColor,
                                backgroundImage: gradient.backgroundImage || 'none'
                            }} />
                            {key.charAt(0).toUpperCase() + key.slice(1)}
                        </MenuItem>
                    );
                })}
            </StyledSelect>
        </StyledSelectContainer>
    );
}

export default PresetSelector;