const translations = {
    en: {
        update_app: "You need to update the telegram to continue using coob.app",
        select_language_app: "Select App Language",
        completed: "Done!",
        continue: "Keep Going",
        profile: "My Profile",
        assignments: "Tasks",
        payments: "Payments",
        lessons: "Lessons",
        dashboard: "Home",
        progress: "Progress",
        edit_profile: "Update Profile",
        attempts: "Tries",
        comments: "Feedback",
        created: "Created",
        attempt: "Try",
        start: "Start",
        no_notifications: "No notifications to show.",
        no_assignments: "No tasks to show.",
        status: "Status",
        notifications: "Notifications",
        date: "Date",
        no_payments: "No completed payments ⭐",
        current_step_locked: "Complete this step to unlock the next one.",
        step_locked: "Complete the previous steps so that the content is available.",
        reset_progress: "Reset Progress",
        reset_confirm_message: "Are you sure you want to reset your progress? This action cannot be undone.",
        courses: "Recently Created Courses",
        add_course: "Add Course",
        create_course: "Create Course",
        no_courses: "You don't create any courses yet.",
        create_course_header: "Create a Course to Share Your Knowledge",
        delete_course_confirmation: "Delete Course",
        delete_course_warning: "Are you sure you want to delete this course? This action cannot be undone.",
        delete_step_warning: "Are you sure you want to delete this step? This action cannot be undone.",
        delete_component_warning: "Are you sure you want to delete this component? This action cannot be undone.",
        cancel: "Cancel",
        delete: "Delete",
        save: "Save",
        publish_course: "Publish Course",
        available_bots: "Available Telegram Bots",
        description: "Description",
        title: "Title",
        empty: "None",
        choose_preset: "Choose a Preset",
        choose_image: "Choose Image",
        upload_cover_image: "Upload Cover Image",
        upload: "Upload",
        generate: "Generate",
        edit: "Edit",
        draft: "Draft",
        invoice: "Invoice",
        public: "Public",
        image: "Image",
        text: "Text",
        publish_step: "Publish Step",
        amount: "Amount (Stars)",
        edit_step: "Edit step",
        edit_course: "Edit course",
        courses_br: "Courses",
        Text: "Text",
        Image: "Image",
        Video: "Video",
        Document: "Document",
        Code: "Code",
        Audio: "Audio",
        Checkbox: "Checkbox",
        Painter: "Painter",
        Matcher: "Matcher",
        Transcriber: "Transcriber",
        Substituter: "Substituter",
        CodeVisual: "Code Visual",
        Markdown: "Markdown",
        Editor: "Editor",
        Basic: "Basic",
        Simulator: "Simulator",
        System: "System",
        Assignment: "Assignment",
        Other: "Other",
        Programming: "Programming",
        Codeletix: "Codeletix",
        add_content: "Enter some text and press Enter ...",
        add_content_empty: "Add content to your lesson here ...",
        success_popup_title: "Success!",
        error_popup_title: "Error!",
        warning_popup_title: "Warning!",
        info_popup_title: "Info!",
        close: "Close",
        add_image: "Image",
        add_text: "Text",
        add_invoice: "Invoice",
        content: "Content",
        approved: "Approved",
        rejected: "Rejected",
        pending: "Pending",
        pending_assignments: "Pending Assignments",
        all_assignments: "All Assignments",
        assignment_preview: "Assignment preview",
        assignment_comment: "Comment to the answer",
        actions: "Actions",
        review_assignment_actions: "Review Assignment Actions",
        approve: "Approve",
        decline: "Decline",
        comment_required: "Comment is required",
        assignment: "Assignment",
        reply: "Reply",
        send: "Send",
        courses_menu: "Courses",
        assignments_menu: "Tasks",
        notifications_menu: "Notifications",
        payments_menu: "Payments",
        profile_menu: "Profile",
        bots_menu: "Bots",
        let_feedback: "Leave a comment (optional)",
        subscription_now: "Subscribe",
        subscription_is_active: "Subscription is active",
        in_month: "per month",
        price: "Price",
        no_plan_found: "Plan not found",
        invoice_step: "Invoice Step",
        assignment_plugin: "Assignment Plugin",
        export_course: "Export Course",
        student_statistics: "Student Statistics",
        course_statistics: "Course Statistics",
        custom_plugin: "Custom Plugin",
        ai_generator: "AI Generator",
        yes: "Yes",
        no: "No",
        standard: "Subscription Plan: Standard",
        unlimited: "Subscription Plan: Unlimited",
        premium: "Subscription Plan: Premium",
        create_bot: "Create Bot",
        creating_courses: "Creating Courses",
        subscription_end_date: "Subscription End Date",
        payment_success: "Payment Success",
        constructor: "Constructor",
        statistics: "Statistics",
        students: "Students",
        bots: "Bots",
        no_bots: "No bots to show.",
        set_public: "Set Public",
        course_published: "Course Published",
        publish_course_header: "Ready to publish your course and attach to bot?",
        not_publish_course_header: "Are you sure you want to unpublish this course?",
        bot_select_header: "Select a bot to publish",
        no_bots_message: "You don't have any telegram bots yet 🙁",
        follow_steps_to_create_bot: "Follow the steps below to create a new bot:",
        step_1_find_botfather: "Step 1: Open Telegram and Find @BotFather",
        open_telegram_search_botfather: "Open Telegram and search for @BotFather.",
        select_verified_botfather: "In the search bar at the top, type @BotFather and select the verified account with a blue checkmark.",
        step_2_create_new_bot: "Step 2: Create a New Bot",
        send_newbot_command: "Send the message /newbot to @BotFather.",
        choose_bot_name: "@BotFather will ask you to choose a name for your bot. For example: 'MyLearningBot'.",
        create_bot_username: "Then, you'll need to create a username for your bot. The username must end with 'bot' (e.g., mylearning_bot).",
        step_3_register_bot: "Step 3: Register Your Bot in coob.app",
        forward_api_code: "Forward the message with API code from @BotFather to @coob_app_bot in Telegram.",
        bot_ready_message: "Congratulations! Your bot is now ready to be used in coob project. 🎉",
        select_bot_message: "Select or Create a bot to publish your course.",
        no_students: "You don't have any students yet.",
        no_more_students: "...",
        course_progress: "Course Progress",
        course_title: "Course Title",
        coming_soon: "This section will be available soon",
        plugin_manager: "Install More Components",
        marketplace: "Marketplace",
        installed: "Installed",
        my_components: "My Components",
        components_manager: "Components Manager",
        search_components: "Search components...",
        back: "Back",
        develop_plugin_title: "You have the opportunity to develop a plugin for your course.",
        develop_plugin_description: "Follow the quickstart guide to create a custom plugin for your course.",
        develop_plugin_link_text: "View Documentation",
        by_courses: "Courses",
        filter_by_course: "Filter by Course",
        all_courses: "All",
        clear_filter: "Clear Filter",
        enrolled_courses: "Enrolled Courses",
        username_f: "Username",
        id_f: "ID",
        students_br: "Students",
        no_transactions: "No transactions to show.",
        transactions: "Transactions",
        all: "All",
        incoming: "Incoming",
        refund: "Refund",
        free: "Try for Free!",
        month: "Month",
        activate_free_plan: "Try Free",
        content_alert_title: "Tip",
        content_alert_body: "If you find that editing the course in the app is inconvenient, try using the desktop version.",
        quote: "Quote",
        subscription_info: "Subscription Info",
        teacher: "Teacher",
        student: "Student",
        subscription_is_inactive: "Subscription is inactive",
        news: "News",
        support: "Support",
        documentations: "Documentations",
        faq: "FAQ",
        subscription_block_title: "Subscription",
        other_block_title: "Information",
        welcome_title: "👋 Hi, %s!",
        ok_button: "OK",
        welcome_message: "We’ve automatically detected your language, but if something is wrong, you can change it now or later in your profile settings. 😉",
        examples: "Examples of Courses",
        example_sh: "Examples",
        language: "Language",
        current_language: "Current Language",
        ru: "Русский",
        ru_source: "Russian",
        en: "English",
        en_source: "English",
        es: "Español",
        es_source: "Spanish",
        fr: "Français",
        fr_source: "French",
        de: "Deutsch",
        de_source: "German",
        zh: "中文",
        zh_source: "Chinese",
        select_language: "Select Language",
    },
    ru: {
        update_app: "Вам нужно обновить телеграм, чтобы продолжить использовать coob.app",
        select_language_app: "Выберите язык приложения",
        select_language: "Выберите язык",
        ru: "Русский",
        ru_source: "Русский",
        en: "English",
        en_source: "Английский",
        es: "Español",
        es_source: "Испанский",
        fr: "Français",
        fr_source: "Французский",
        de: "Deutsch",
        de_source: "Немецкий",
        zh: "中文",
        zh_source: "Китайский",
        language: "Язык",
        examples: "Примеры курсов",
        example_sh: "Примеры",
        welcome_message: "Мы определили твой язык автоматически, но если что-то не так — ты можешь изменить его прямо сейчас или позже в настройках профиля. 😉",
        ok_button: "ОК",
        welcome_title: "👋 Привет, %s!",
        other_block_title: "Информация",
        subscription_block_title: "Подписка",
        faq: "FAQ",
        documentations: "Документация",
        news: "Новости",
        support: "Поддержка",
        subscription_is_inactive: "Подписка не активна",
        student: "Студент",
        teacher: "Преподаватель",
        subscription_info: "Информация о подписке",
        quote: "Цитата",
        content_alert_title: "Совет",
        content_alert_body: "Если вам покажется, что редактирование курса в приложении не удобно, попробуйте воспользоваться версией для компьютера.",
        activate_free_plan: "Попробовать бесплатно",
        month: "Месяц",
        free: "Попробовать бесплатно!",
        refund: "Возврат",
        all: "Все",
        incoming: "Входящие",
        transactions: "Транзакции",
        no_transactions: "У Вас пока нет транзакций.",
        students_br: "Студенты",
        id_f: "ID",
        username_f: "Имя пользователя",
        enrolled_courses: "Курсы",
        clear_filter: "Сбросить Фильтр",
        all_courses: "Все",
        filter_by_course: "Фильтр по Курсу",
        by_courses: "Курсы",
        develop_plugin_title: "У вас есть возможность разработать плагин для вашего курса.",
        develop_plugin_description: "Следуйте руководству Quickstart, чтобы создать собственный плагин для вашего курса.",
        develop_plugin_link_text: "Посмотреть документацию",
        back: "Назад",
        search_components: "Поиск компонентов...",
        components_manager: "Менеджер Компонентов",
        my_components: "Мои Компоненты",
        installed: "Установлено",
        marketplace: "Маркетплейс",
        plugin_manager: "Установить еще компоненты",
        coming_soon: "Раздел в скором времени будет доступен",
        course_title: "Название Курса",
        course_progress: "Прогресс Обучения",
        no_more_students: "...",
        no_students: "У вас пока нет студентов.",
        select_bot_message: "Выберите или добавьте бота для публикации вашего курса.",
        bot_ready_message: "Поздравляем! Ваш бот теперь готов к использованию в проекте coob. 🎉",
        forward_api_code: "Перешлите сообщение с API-кодом от @BotFather в @coob_app_bot в Telegram.",
        step_3_register_bot: "Шаг 3: Зарегистрируйте своего бота в coob.app",
        create_bot_username: "Затем вам нужно будет создать имя пользователя для вашего бота. Имя пользователя должно заканчиваться на 'bot' (например, mylearning_bot).",
        choose_bot_name: "@BotFather попросит вас выбрать имя для вашего бота. Например: 'MyLearningBot'.",
        send_newbot_command: "Отправьте сообщение /newbot @BotFather.",
        step_2_create_new_bot: "Шаг 2: Создайте нового бота",
        select_verified_botfather: "В строке поиска вверху введите @BotFather и выберите верифицированный аккаунт с голубой галочкой.",
        open_telegram_search_botfather: "Откройте Telegram и найдите @BotFather.",
        step_1_find_botfather: "Шаг 1: Откройте Telegram и найдите @BotFather",
        follow_steps_to_create_bot: "Следуйте шагам ниже, чтобы создать нового бота:",
        no_bots_message: "У вас пока нет телеграм ботов 🙁",

        bot_select_header: "Выберите бота для публикации",
        not_publish_course_header: "Вы уверены, что хотите снять публикацию с этого курса?",
        publish_course_header: "Готовы опубликовать свой курс и привязать к боту?",
        course_published: "Курс Опубликован",
        set_public: "Опубликовать",
        no_bots: "Нет ботов для показа.",
        bots: "Боты",
        students: "Студенты",
        statistics: "Статистика",
        constructor: "Конструктор",
        payment_success: "Оплата прошла успешно",
        subscription_end_date: "Дата окончания подписки",
        creating_courses: "Создание Курсов",
        create_bot: "Добавление Ботов",
        premium: "Подписка: Премиум",
        unlimited: "Подписка: Безлимит",
        standard: "Подписка: Стандарт",
        no: "Нет",
        yes: "Да",
        ai_generator: "Генератор ИИ",
        custom_plugin: "Пользовательский Плагин",
        course_statistics: "Статистика Курса",
        student_statistics: "Статистика Студентов",
        export_course: "Экспорт Курса",
        assignment_plugin: "Компоненты Заданий",
        invoice_step: "Шаг оплаты",
        price: "Стоимость",
        in_month: "в месяц",
        subscription_is_active: "Подписка активна",
        subscription_now: "Подписаться",
        let_feedback: "Оставьте комментарий (опционально)",
        bots_menu: "Боты",
        profile_menu: "Профиль",
        payments_menu: "Платежи",
        notifications_menu: "Уведомления",
        assignments_menu: "Задания",
        courses_menu: "Курсы",
        send: "Отправить",
        reply: "Ответить",
        assignment: "Задание",
        comment_required: "Комментарий обязателен",
        decline: "Отклонить",
        approve: "Принять",
        review_assignment_actions: "Ревью задания",
        assignment_comment: "Комментарий к ответу",
        assignment_preview: "Просмотр задания",
        all_assignments: "Все задания",
        pending_assignments: "Задания ожидающие проверки",
        pending: "В ожидании",
        rejected: "Отклонено",
        content: "Контент",
        add_invoice: "Оплата",
        add_text: "Текст",
        add_image: "Изображение",
        close: "Закрыть",
        info_popup_title: "Информация!",
        warning_popup_title: "Предупреждение!",
        error_popup_title: "Ошибка!",
        success_popup_title: "Отлично!",
        add_content_empty: "Добавляйте контент к вашему уроку здесь ...",
        add_content: "Введите текст и нажмите Enter ...",
        Codeletix: "Кодлетикс",
        Other: "Другое",
        Assignment: "Задание",
        System: "Системные",
        Simulator: "Упражнения",
        Basic: "Основные",
        Editor: "Редактор",
        Markdown: "Маркдаун",
        CodeVisual: "Визуализация Кода",
        Substituter: "Замена",
        Transcriber: "Транскрипция",
        Matcher: "Сопоставление",
        Painter: "Рисование",
        Checkbox: "Чекбокс",
        Audio: "Аудио",
        Code: "Код",
        Document: "Документ",
        Video: "Видео",
        Image: "Изображение",
        Text: "Текст",
        Programming: "Программирование",
        courses_br: "Курсы",
        edit_course: "Редактировать курс",
        edit_step: "Редактировать шаг",
        amount: "Сумма (Stars)",
        publish_step: "Опубликовать Шаг",
        text: "Текст",
        image: "Изображение",
        public: "Опубликовано",
        invoice: "Счет",
        draft: "Черновик",
        edit: "Редактировать",
        generate: "Сгенерировать",
        upload: "Загрузить",
        upload_cover_image: "Загрузить обложку",
        choose_image: "Выберите изображение",
        choose_preset: "Выберите шаблон",
        empty: "Пусто",
        title: "Название",
        description: "Описание",
        available_bots: "Доступные Телеграм Боты",
        publish_course: "Опубликовать Курс",
        save: "Сохранить",
        create_course_header: "Создайте курс, чтобы поделиться своими знаниями",
        create_course: "Создать Курс",
        add_course: "Добавить Курс",
        no_courses: "Вы еще не создали ни одного курса.",
        courses: "Последние созданные курсы",
        completed: "Готово!",
        reset_progress: "Сбросить Прогресс",
        reset_confirm_message: "Вы уверены, что хотите сбросить свой прогресс? Это действие нельзя отменить.",
        delete_step_warning: "Вы уверены, что хотите удалить этот шаг? Это действие нельзя отменить.",
        delete_component_warning: "Вы уверены, что хотите удалить этот компонент? Это действие нельзя отменить.",
        continue: "Продолжить",
        start: "Начать",
        profile: "Мой Профиль",
        notifications: "Уведомления",
        no_notifications: "Нет уведомлений для показа.",
        assignments: "Задания",
        lessons: "Уроки",
        payments: "Платежи",
        dashboard: "Главная",
        progress: "Прогресс",
        edit_profile: "Обновить Профиль",
        attempts: "Попытки",
        comments: "Отзывы",
        created: "Создано",
        attempt: "Попробовать",
        no_assignments: "Нет заданий для показа.",
        status: "Статус",
        date: "Дата",
        no_payments: "Нет совершенных платежей ⭐",
        current_step_locked: "Завершите этот шаг, чтобы разблокировать следующий.",
        step_locked: "Завершите предыдущее шаги, чтобы контент был доступен.",
        delete_course_confirmation: "Удалить Курс",
        delete_course_warning: "Вы уверены, что хотите удалить этот курс? Это действие нельзя отменить.",
        cancel: "Отмена",
        delete: "Удалить",
        approved: "Принято",
        no_plan_found: "План не найден",
    },
    es: {
        update_app: "Necesitas actualizar Telegram para seguir usando coob.app",
        select_language_app: "Selecciona el idioma de la aplicación",
        completed: "¡Hecho!",
        continue: "Sigue adelante",
        profile: "Mi perfil",
        assignments: "Tareas",
        payments: "Pagos",
        lessons: "Lecciones",
        dashboard: "Inicio",
        progress: "Progreso",
        edit_profile: "Actualizar perfil",
        attempts: "Intentos",
        comments: "Comentarios",
        created: "Creado",
        attempt: "Intentar",
        start: "Comenzar",
        no_notifications: "No hay notificaciones para mostrar.",
        no_assignments: "No hay tareas para mostrar.",
        status: "Estado",
        notifications: "Notificaciones",
        date: "Fecha",
        no_payments: "No hay pagos completados ⭐",
        current_step_locked: "Completa este paso para desbloquear el siguiente.",
        step_locked: "Completa los pasos anteriores para que el contenido esté disponible.",
        reset_progress: "Restablecer progreso",
        reset_confirm_message: "¿Estás seguro de que deseas restablecer tu progreso? Esta acción no se puede deshacer.",
        courses: "Cursos creados recientemente",
        add_course: "Agregar curso",
        create_course: "Crear curso",
        no_courses: "Aún no has creado ningún curso.",
        create_course_header: "Crea un curso para compartir tu conocimiento",
        delete_course_confirmation: "Eliminar curso",
        delete_course_warning: "¿Estás seguro de que deseas eliminar este curso? Esta acción no se puede deshacer.",
        delete_step_warning: "¿Estás seguro de que deseas eliminar este paso? Esta acción no se puede deshacer.",
        delete_component_warning: "¿Estás seguro de que deseas eliminar este componente? Esta acción no se puede deshacer.",
        cancel: "Cancelar",
        delete: "Eliminar",
        save: "Guardar",
        publish_course: "Publicar curso",
        available_bots: "Bots de Telegram disponibles",
        description: "Descripción",
        title: "Título",
        empty: "Ninguno",
        choose_preset: "Elegir un preset",
        choose_image: "Elegir imagen",
        upload_cover_image: "Subir imagen de portada",
        upload: "Subir",
        generate: "Generar",
        edit: "Editar",
        draft: "Borrador",
        invoice: "Factura",
        public: "Público",
        image: "Imagen",
        text: "Texto",
        publish_step: "Publicar paso",
        amount: "Cantidad (Stars)",
        edit_step: "Editar paso",
        edit_course: "Editar curso",
        courses_br: "Cursos",
        Text: "Texto",
        Image: "Imagen",
        Video: "Video",
        Document: "Documento",
        Code: "Código",
        Audio: "Audio",
        Checkbox: "Casilla de verificación",
        Painter: "Pintor",
        Matcher: "Emparejador",
        Transcriber: "Transcriptor",
        Substituter: "Sustituidor",
        CodeVisual: "Visualización de código",
        Markdown: "Markdown",
        Editor: "Editor",
        Basic: "Básico",
        Simulator: "Simulador",
        System: "Sistema",
        Assignment: "Tarea",
        Other: "Otro",
        Programming: "Programación",
        Codeletix: "Codeletix",
        add_content: "Escribe un texto y presiona Enter ...",
        add_content_empty: "Agrega contenido a tu lección aquí ...",
        success_popup_title: "¡Éxito!",
        error_popup_title: "¡Error!",
        warning_popup_title: "¡Advertencia!",
        info_popup_title: "¡Información!",
        close: "Cerrar",
        add_image: "Imagen",
        add_text: "Texto",
        add_invoice: "Factura",
        content: "Contenido",
        approved: "Aprobado",
        rejected: "Rechazado",
        pending: "Pendiente",
        pending_assignments: "Tareas pendientes",
        all_assignments: "Todas las tareas",
        assignment_preview: "Vista previa de la tarea",
        assignment_comment: "Comentario sobre la respuesta",
        actions: "Acciones",
        review_assignment_actions: "Revisar acciones de la tarea",
        approve: "Aprobar",
        decline: "Rechazar",
        comment_required: "Se requiere un comentario",
        assignment: "Tarea",
        reply: "Responder",
        send: "Enviar",
        courses_menu: "Cursos",
        assignments_menu: "Tareas",
        notifications_menu: "Notificaciones",
        payments_menu: "Pagos",
        profile_menu: "Perfil",
        bots_menu: "Bots",
        let_feedback: "Deja un comentario (opcional)",
        subscription_now: "Suscribirse",
        subscription_is_active: "La suscripción está activa",
        in_month: "por mes",
        price: "Precio",
        no_plan_found: "No se encontró un plan",
        invoice_step: "Paso de factura",
        assignment_plugin: "Plugin de tarea",
        export_course: "Exportar curso",
        student_statistics: "Estadísticas de estudiantes",
        course_statistics: "Estadísticas del curso",
        custom_plugin: "Plugin personalizado",
        ai_generator: "Generador AI",
        yes: "Sí",
        no: "No",
        standard: "Plan de suscripción: Estándar",
        unlimited: "Plan de suscripción: Ilimitado",
        premium: "Plan de suscripción: Premium",
        create_bot: "Crear bot",
        creating_courses: "Creando cursos",
        subscription_end_date: "Fecha de finalización de la suscripción",
        payment_success: "Pago exitoso",
        constructor: "Constructor",
        statistics: "Estadísticas",
        students: "Estudiantes",
        bots: "Bots",
        no_bots: "No hay bots para mostrar.",
        set_public: "Hacer público",
        course_published: "Curso publicado",
        publish_course_header: "¿Listo para publicar tu curso y adjuntarlo a un bot?",
        not_publish_course_header: "¿Estás seguro de que deseas dejar de publicar este curso?",
        bot_select_header: "Selecciona un bot para publicar",
        no_bots_message: "Aún no tienes ningún bot de Telegram 🙁",
        follow_steps_to_create_bot: "Sigue los pasos a continuación para crear un nuevo bot:",
        step_1_find_botfather: "Paso 1: Abre Telegram y busca @BotFather",
        open_telegram_search_botfather: "Abre Telegram y busca @BotFather.",
        select_verified_botfather: "En la barra de búsqueda superior, escribe @BotFather y selecciona la cuenta verificada con una marca azul.",
        step_2_create_new_bot: "Paso 2: Crea un nuevo bot",
        send_newbot_command: "Envía el mensaje /newbot a @BotFather.",
        choose_bot_name: "@BotFather te pedirá que elijas un nombre para tu bot. Por ejemplo: 'MiBotDeAprendizaje'.",
        create_bot_username: "Luego, deberás crear un nombre de usuario para tu bot. El nombre de usuario debe terminar en 'bot' (por ejemplo, mibotaprendizaje_bot).",
        step_3_register_bot: "Paso 3: Registra tu bot en coob.app",
        forward_api_code: "Reenvía el mensaje con el código API de @BotFather a @coob_app_bot en Telegram.",
        bot_ready_message: "¡Felicidades! Tu bot ya está listo para usarse en el proyecto coob. 🎉",
        select_bot_message: "Selecciona o crea un bot para publicar tu curso.",
        no_students: "Aún no tienes estudiantes.",
        no_more_students: "...",
        course_progress: "Progreso del curso",
        course_title: "Título del curso",
        coming_soon: "Esta sección estará disponible pronto",
        plugin_manager: "Instalar más componentes",
        marketplace: "Mercado",
        installed: "Instalado",
        my_components: "Mis componentes",
        components_manager: "Gestor de componentes",
        search_components: "Buscar componentes...",
        back: "Volver",
        develop_plugin_title: "Tienes la oportunidad de desarrollar un plugin para tu curso.",
        develop_plugin_description: "Sigue la guía rápida para crear un plugin personalizado para tu curso.",
        develop_plugin_link_text: "Ver documentación",
        by_courses: "Cursos",
        filter_by_course: "Filtrar por curso",
        all_courses: "Todos",
        clear_filter: "Borrar filtro",
        enrolled_courses: "Cursos inscritos",
        username_f: "Nombre de usuario",
        id_f: "ID",
        students_br: "Estudiantes",
        no_transactions: "No hay transacciones para mostrar.",
        transactions: "Transacciones",
        all: "Todos",
        incoming: "Entrantes",
        refund: "Reembolso",
        free: "¡Prueba gratis!",
        month: "Mes",
        activate_free_plan: "Prueba gratuita",
        content_alert_title: "Consejo",
        content_alert_body: "Si editar el curso en la app es incómodo, prueba la versión de escritorio.",
        quote: "Cita",
        subscription_info: "Información de suscripción",
        teacher: "Profesor",
        student: "Estudiante",
        subscription_is_inactive: "La suscripción está inactiva",
        news: "Noticias",
        support: "Soporte",
        documentations: "Documentación",
        faq: "Preguntas Frecuentes",
        subscription_block_title: "Suscripción",
        other_block_title: "Información",
        welcome_title: "👋 ¡Hola, %s!",
        ok_button: "OK",
        welcome_message: "Hemos detectado tu idioma automáticamente, pero si hay algún error, puedes cambiarlo ahora o más tarde en la configuración de tu perfil. 😉",
        examples: "Ejemplos de cursos",
        example_sh: "Ejemplos",
        language: "Idioma",
        current_language: "Idioma actual",
        ru: "Русский",
        ru_source: "Ruso",
        en: "English",
        en_source: "Inglés",
        es: "Español",
        es_source: "Español",
        fr: "Français",
        fr_source: "Francés",
        de: "Deutsch",
        de_source: "Alemán",
        zh: "中文",
        zh_source: "Chino",
        select_language: "Seleccionar idioma",
    },
    fr: {
        update_app: "Vous devez mettre à jour Telegram pour continuer à utiliser coob.app",
        select_language_app: "Sélectionnez la langue de l'application",
        completed: "Terminé !",
        continue: "Continuer",
        profile: "Mon profil",
        assignments: "Tâches",
        payments: "Paiements",
        lessons: "Leçons",
        dashboard: "Accueil",
        progress: "Progression",
        edit_profile: "Mettre à jour le profil",
        attempts: "Essais",
        comments: "Commentaires",
        created: "Créé",
        attempt: "Essayer",
        start: "Commencer",
        no_notifications: "Aucune notification à afficher.",
        no_assignments: "Aucune tâche à afficher.",
        status: "Statut",
        notifications: "Notifications",
        date: "Date",
        no_payments: "Aucun paiement complété ⭐",
        current_step_locked: "Complétez cette étape pour débloquer la suivante.",
        step_locked: "Complétez les étapes précédentes pour accéder au contenu.",
        reset_progress: "Réinitialiser la progression",
        reset_confirm_message: "Êtes-vous sûr de vouloir réinitialiser votre progression ? Cette action est irréversible.",
        courses: "Cours récemment créés",
        add_course: "Ajouter un cours",
        create_course: "Créer un cours",
        no_courses: "Vous n'avez pas encore créé de cours.",
        create_course_header: "Créez un cours pour partager vos connaissances",
        delete_course_confirmation: "Supprimer le cours",
        delete_course_warning: "Êtes-vous sûr de vouloir supprimer ce cours ? Cette action est irréversible.",
        delete_step_warning: "Êtes-vous sûr de vouloir supprimer cette étape ? Cette action est irréversible.",
        delete_component_warning: "Êtes-vous sûr de vouloir supprimer ce composant ? Cette action est irréversible.",
        cancel: "Annuler",
        delete: "Supprimer",
        save: "Enregistrer",
        publish_course: "Publier le cours",
        available_bots: "Bots Telegram disponibles",
        description: "Description",
        title: "Titre",
        empty: "Aucun",
        choose_preset: "Choisir un préréglage",
        choose_image: "Choisir une image",
        upload_cover_image: "Téléverser une image de couverture",
        upload: "Téléverser",
        generate: "Générer",
        edit: "Éditer",
        draft: "Brouillon",
        invoice: "Facture",
        public: "Public",
        image: "Image",
        text: "Texte",
        publish_step: "Publier l'étape",
        amount: "Montant (Stars)",
        edit_step: "Modifier l'étape",
        edit_course: "Modifier le cours",
        courses_br: "Cours",
        Text: "Texte",
        Image: "Image",
        Video: "Vidéo",
        Document: "Document",
        Code: "Code",
        Audio: "Audio",
        Checkbox: "Case à cocher",
        Painter: "Peintre",
        Matcher: "Association",
        Transcriber: "Transcripteur",
        Substituter: "Substituteur",
        CodeVisual: "Visualisation de code",
        Markdown: "Markdown",
        Editor: "Éditeur",
        Basic: "Basique",
        Simulator: "Simulateur",
        System: "Système",
        Assignment: "Devoir",
        Other: "Autre",
        Programming: "Programmation",
        Codeletix: "Codeletix",
        add_content: "Écrivez du texte et appuyez sur Entrée...",
        add_content_empty: "Ajoutez du contenu à votre leçon ici...",
        success_popup_title: "Succès !",
        error_popup_title: "Erreur !",
        warning_popup_title: "Avertissement !",
        info_popup_title: "Info !",
        close: "Fermer",
        add_image: "Image",
        add_text: "Texte",
        add_invoice: "Facture",
        content: "Contenu",
        approved: "Approuvé",
        rejected: "Rejeté",
        pending: "En attente",
        pending_assignments: "Tâches en attente",
        all_assignments: "Toutes les tâches",
        assignment_preview: "Aperçu du devoir",
        assignment_comment: "Commentaire sur la réponse",
        actions: "Actions",
        review_assignment_actions: "Examiner les actions du devoir",
        approve: "Approuver",
        decline: "Refuser",
        comment_required: "Un commentaire est requis",
        assignment: "Devoir",
        reply: "Répondre",
        send: "Envoyer",
        courses_menu: "Cours",
        assignments_menu: "Tâches",
        notifications_menu: "Notifications",
        payments_menu: "Paiements",
        profile_menu: "Profil",
        bots_menu: "Bots",
        let_feedback: "Laissez un commentaire (optionnel)",
        subscription_now: "S'abonner",
        subscription_is_active: "L'abonnement est actif",
        in_month: "par mois",
        price: "Prix",
        no_plan_found: "Aucun plan trouvé",
        invoice_step: "Étape de facturation",
        assignment_plugin: "Plugin de devoir",
        export_course: "Exporter le cours",
        student_statistics: "Statistiques des étudiants",
        course_statistics: "Statistiques du cours",
        custom_plugin: "Plugin personnalisé",
        ai_generator: "Générateur AI",
        yes: "Oui",
        no: "Non",
        standard: "Plan d'abonnement : Standard",
        unlimited: "Plan d'abonnement : Illimité",
        premium: "Plan d'abonnement : Premium",
        create_bot: "Créer un bot",
        creating_courses: "Création de cours",
        subscription_end_date: "Date de fin d'abonnement",
        payment_success: "Paiement réussi",
        constructor: "Constructeur",
        statistics: "Statistiques",
        students: "Étudiants",
        bots: "Bots",
        no_bots: "Aucun bot à afficher.",
        set_public: "Rendre public",
        course_published: "Cours publié",
        publish_course_header: "Prêt à publier votre cours et l'attacher à un bot ?",
        not_publish_course_header: "Êtes-vous sûr de vouloir annuler la publication de ce cours ?",
        bot_select_header: "Sélectionnez un bot pour publier",
        no_bots_message: "Vous n'avez pas encore de bots Telegram 🙁",
        follow_steps_to_create_bot: "Suivez les étapes ci-dessous pour créer un nouveau bot :",
        step_1_find_botfather: "Étape 1 : Ouvrez Telegram et trouvez @BotFather",
        open_telegram_search_botfather: "Ouvrez Telegram et recherchez @BotFather.",
        select_verified_botfather: "Dans la barre de recherche en haut, tapez @BotFather et sélectionnez le compte vérifié avec une coche bleue.",
        step_2_create_new_bot: "Étape 2 : Créez un nouveau bot",
        send_newbot_command: "Envoyez le message /newbot à @BotFather.",
        choose_bot_name: "@BotFather vous demandera de choisir un nom pour votre bot. Par exemple : 'MonBotApprentissage'.",
        create_bot_username: "Ensuite, vous devrez créer un nom d'utilisateur pour votre bot. Le nom d'utilisateur doit se terminer par 'bot' (exemple : monbot_apprentissage_bot).",
        step_3_register_bot: "Étape 3 : Enregistrez votre bot sur coob.app",
        forward_api_code: "Transférez le message contenant le code API de @BotFather à @coob_app_bot sur Telegram.",
        bot_ready_message: "Félicitations ! Votre bot est maintenant prêt à être utilisé dans le projet coob. 🎉",
        select_bot_message: "Sélectionnez ou créez un bot pour publier votre cours.",
        no_students: "Vous n'avez pas encore d'étudiants.",
        no_more_students: "...",
        course_progress: "Progression du cours",
        course_title: "Titre du cours",
        coming_soon: "Cette section sera bientôt disponible",
        plugin_manager: "Installer plus de composants",
        marketplace: "Marketplace",
        installed: "Installé",
        my_components: "Mes composants",
        components_manager: "Gestionnaire de composants",
        search_components: "Rechercher des composants...",
        back: "Retour",
        develop_plugin_title: "Vous avez la possibilité de développer un plugin pour votre cours.",
        develop_plugin_description: "Suivez le guide rapide pour créer un plugin personnalisé pour votre cours.",
        develop_plugin_link_text: "Voir la documentation",
        by_courses: "Cours",
        filter_by_course: "Filtrer par cours",
        all_courses: "Tous",
        clear_filter: "Effacer le filtre",
        enrolled_courses: "Cours inscrits",
        username_f: "Nom d'utilisateur",
        id_f: "ID",
        students_br: "Étudiants",
        no_transactions: "Aucune transaction à afficher.",
        transactions: "Transactions",
        all: "Tous",
        incoming: "Entrants",
        refund: "Remboursement",
        free: "Essai gratuit !",
        month: "Mois",
        activate_free_plan: "Essayer gratuitement",
        content_alert_title: "Astuce",
        content_alert_body: "Si l'édition du cours dans l'application est inconfortable, essayez la version de bureau.",
        quote: "Citation",
        subscription_info: "Informations sur l'abonnement",
        teacher: "Enseignant",
        student: "Étudiant",
        subscription_is_inactive: "L'abonnement est inactif",
        news: "Actualités",
        support: "Support",
        documentations: "Documentation",
        faq: "FAQ",
        subscription_block_title: "Abonnement",
        other_block_title: "Informations",
        welcome_title: "👋 Bonjour, %s !",
        ok_button: "OK",
        welcome_message: "Nous avons détecté ta langue automatiquement, mais si ce n’est pas correct, tu peux la modifier dès maintenant ou plus tard dans les paramètres de ton profil. 😉",
        examples: "Exemples de cours",
        example_sh: "Exemples",
        language: "Langue",
        current_language: "Langue actuelle",
        ru: "Русский",
        ru_source: "Russe",
        en: "English",
        en_source: "Anglais",
        es: "Español",
        es_source: "Espagnol",
        fr: "Français",
        fr_source: "Français",
        de: "Deutsch",
        de_source: "Allemand",
        zh: "中文",
        zh_source: "Chinois",
        select_language: "Sélectionner la langue",
    },
    de: {
        update_app: "Du musst Telegram aktualisieren, um coob.app weiterhin zu verwenden",
        select_language_app: "Wähle die Sprache der App",
        completed: "Erledigt!",
        continue: "Weiter",
        profile: "Mein Profil",
        assignments: "Aufgaben",
        payments: "Zahlungen",
        lessons: "Lektionen",
        dashboard: "Startseite",
        progress: "Fortschritt",
        edit_profile: "Profil aktualisieren",
        attempts: "Versuche",
        comments: "Feedback",
        created: "Erstellt",
        attempt: "Versuch",
        start: "Start",
        no_notifications: "Keine Benachrichtigungen vorhanden.",
        no_assignments: "Keine Aufgaben vorhanden.",
        status: "Status",
        notifications: "Benachrichtigungen",
        date: "Datum",
        no_payments: "Keine abgeschlossenen Zahlungen ⭐",
        current_step_locked: "Schließe diesen Schritt ab, um den nächsten freizuschalten.",
        step_locked: "Schließe die vorherigen Schritte ab, um den Inhalt verfügbar zu machen.",
        reset_progress: "Fortschritt zurücksetzen",
        reset_confirm_message: "Bist du sicher, dass du deinen Fortschritt zurücksetzen möchtest? Diese Aktion kann nicht rückgängig gemacht werden.",
        courses: "Kürzlich erstellte Kurse",
        add_course: "Kurs hinzufügen",
        create_course: "Kurs erstellen",
        no_courses: "Du hast noch keine Kurse erstellt.",
        create_course_header: "Erstelle einen Kurs, um dein Wissen zu teilen",
        delete_course_confirmation: "Kurs löschen",
        delete_course_warning: "Bist du sicher, dass du diesen Kurs löschen möchtest? Diese Aktion kann nicht rückgängig gemacht werden.",
        delete_step_warning: "Bist du sicher, dass du diesen Schritt löschen möchtest? Diese Aktion kann nicht rückgängig gemacht werden.",
        delete_component_warning: "Bist du sicher, dass du diese Komponente löschen möchtest? Diese Aktion kann nicht rückgängig gemacht werden.",
        cancel: "Abbrechen",
        delete: "Löschen",
        save: "Speichern",
        publish_course: "Kurs veröffentlichen",
        available_bots: "Verfügbare Telegram-Bots",
        description: "Beschreibung",
        title: "Titel",
        empty: "Keine",
        choose_preset: "Vorlage auswählen",
        choose_image: "Bild auswählen",
        upload_cover_image: "Titelbild hochladen",
        upload: "Hochladen",
        generate: "Generieren",
        edit: "Bearbeiten",
        draft: "Entwurf",
        invoice: "Rechnung",
        public: "Öffentlich",
        image: "Bild",
        text: "Text",
        publish_step: "Schritt veröffentlichen",
        amount: "Betrag (Stars)",
        edit_step: "Schritt bearbeiten",
        edit_course: "Kurs bearbeiten",
        courses_br: "Kurse",
        Text: "Text",
        Image: "Bild",
        Video: "Video",
        Document: "Dokument",
        Code: "Code",
        Audio: "Audio",
        Checkbox: "Kontrollkästchen",
        Painter: "Zeichner",
        Matcher: "Zuordnung",
        Transcriber: "Transkriptor",
        Substituter: "Ersetzer",
        CodeVisual: "Code-Visualisierung",
        Markdown: "Markdown",
        Editor: "Editor",
        Basic: "Basis",
        Simulator: "Simulator",
        System: "System",
        Assignment: "Aufgabe",
        Other: "Andere",
        Programming: "Programmierung",
        Codeletix: "Codeletix",
        add_content: "Gib einen Text ein und drücke Enter ...",
        add_content_empty: "Füge hier Inhalte zu deiner Lektion hinzu ...",
        success_popup_title: "Erfolg!",
        error_popup_title: "Fehler!",
        warning_popup_title: "Warnung!",
        info_popup_title: "Info!",
        close: "Schließen",
        add_image: "Bild",
        add_text: "Text",
        add_invoice: "Rechnung",
        content: "Inhalt",
        approved: "Genehmigt",
        rejected: "Abgelehnt",
        pending: "Ausstehend",
        pending_assignments: "Ausstehende Aufgaben",
        all_assignments: "Alle Aufgaben",
        assignment_preview: "Aufgaben-Vorschau",
        assignment_comment: "Kommentar zur Antwort",
        actions: "Aktionen",
        review_assignment_actions: "Aufgabe überprüfen",
        approve: "Genehmigen",
        decline: "Ablehnen",
        comment_required: "Kommentar erforderlich",
        assignment: "Aufgabe",
        reply: "Antworten",
        send: "Senden",
        courses_menu: "Kurse",
        assignments_menu: "Aufgaben",
        notifications_menu: "Benachrichtigungen",
        payments_menu: "Zahlungen",
        profile_menu: "Profil",
        bots_menu: "Bots",
        let_feedback: "Hinterlasse einen Kommentar (optional)",
        subscription_now: "Abonnieren",
        subscription_is_active: "Abonnement ist aktiv",
        in_month: "pro Monat",
        price: "Preis",
        no_plan_found: "Kein Plan gefunden",
        invoice_step: "Rechnungsschritt",
        assignment_plugin: "Aufgaben-Plugin",
        export_course: "Kurs exportieren",
        student_statistics: "Studenten-Statistiken",
        course_statistics: "Kurs-Statistiken",
        custom_plugin: "Benutzerdefiniertes Plugin",
        ai_generator: "KI-Generator",
        yes: "Ja",
        no: "Nein",
        standard: "Abonnementplan: Standard",
        unlimited: "Abonnementplan: Unbegrenzt",
        premium: "Abonnementplan: Premium",
        create_bot: "Bot erstellen",
        creating_courses: "Kurse erstellen",
        subscription_end_date: "Enddatum des Abonnements",
        payment_success: "Zahlung erfolgreich",
        constructor: "Konstruktor",
        statistics: "Statistiken",
        students: "Studenten",
        bots: "Bots",
        no_bots: "Keine Bots vorhanden.",
        set_public: "Öffentlich machen",
        course_published: "Kurs veröffentlicht",
        publish_course_header: "Bereit, deinen Kurs zu veröffentlichen und mit einem Bot zu verbinden?",
        not_publish_course_header: "Bist du sicher, dass du die Veröffentlichung dieses Kurses rückgängig machen möchtest?",
        bot_select_header: "Wähle einen Bot zur Veröffentlichung",
        no_bots_message: "Du hast noch keine Telegram-Bots 🙁",
        follow_steps_to_create_bot: "Folge den untenstehenden Schritten, um einen neuen Bot zu erstellen:",
        step_1_find_botfather: "Schritt 1: Öffne Telegram und suche nach @BotFather",
        open_telegram_search_botfather: "Öffne Telegram und suche nach @BotFather.",
        select_verified_botfather: "Gib @BotFather in die Suchleiste ein und wähle das verifizierte Konto mit dem blauen Haken.",
        step_2_create_new_bot: "Schritt 2: Erstelle einen neuen Bot",
        send_newbot_command: "Sende die Nachricht /newbot an @BotFather.",
        choose_bot_name: "@BotFather wird dich bitten, einen Namen für deinen Bot auszuwählen. Zum Beispiel: 'MeinLernBot'.",
        create_bot_username: "Dann musst du einen Benutzernamen für deinen Bot erstellen. Der Benutzername muss mit 'bot' enden (z. B. meinlern_bot).",
        step_3_register_bot: "Schritt 3: Registriere deinen Bot auf coob.app",
        forward_api_code: "Leite die Nachricht mit dem API-Code von @BotFather an @coob_app_bot auf Telegram weiter.",
        bot_ready_message: "Glückwunsch! Dein Bot ist jetzt bereit zur Nutzung im Coob-Projekt. 🎉",
        select_bot_message: "Wähle oder erstelle einen Bot, um deinen Kurs zu veröffentlichen.",
        no_students: "Du hast noch keine Studenten.",
        no_more_students: "...",
        course_progress: "Kursfortschritt",
        course_title: "Kurstitel",
        coming_soon: "Dieser Bereich wird bald verfügbar sein",
        plugin_manager: "Mehr Komponenten installieren",
        marketplace: "Marktplatz",
        installed: "Installiert",
        my_components: "Meine Komponenten",
        components_manager: "Komponenten-Manager",
        search_components: "Komponenten suchen...",
        back: "Zurück",
        develop_plugin_title: "Du hast die Möglichkeit, ein Plugin für deinen Kurs zu entwickeln.",
        develop_plugin_description: "Folge der Schnellstart-Anleitung, um ein benutzerdefiniertes Plugin für deinen Kurs zu erstellen.",
        develop_plugin_link_text: "Dokumentation anzeigen",
        by_courses: "Kurse",
        filter_by_course: "Nach Kurs filtern",
        all_courses: "Alle",
        clear_filter: "Filter löschen",
        enrolled_courses: "Eingeschriebene Kurse",
        username_f: "Benutzername",
        id_f: "ID",
        students_br: "Studenten",
        no_transactions: "Keine Transaktionen vorhanden.",
        transactions: "Transaktionen",
        all: "Alle",
        incoming: "Eingehend",
        refund: "Rückerstattung",
        free: "Kostenlos testen!",
        month: "Monat",
        activate_free_plan: "Kostenlose Testversion",
        content_alert_title: "Tipp",
        content_alert_body: "Falls das Bearbeiten des Kurses in der App umständlich ist, probiere die Desktop-Version.",
        quote: "Zitat",
        subscription_info: "Abonnement-Informationen",
        teacher: "Lehrer",
        student: "Student",
        subscription_is_inactive: "Das Abonnement ist inaktiv",
        news: "Nachrichten",
        support: "Support",
        documentations: "Dokumentationen",
        faq: "FAQ",
        subscription_block_title: "Abonnement",
        other_block_title: "Informationen",
        welcome_title: "👋 Hallo, %s!",
        ok_button: "OK",
        welcome_message: "Wir haben deine Sprache automatisch erkannt, aber falls etwas nicht stimmt, kannst du sie jetzt oder später in den Profileinstellungen ändern. 😉",

        examples: "Beispiele für Kurse",
        example_sh: "Beispiele",
        language: "Sprache",
        current_language: "Aktuelle Sprache",
        ru: "Русский",
        ru_source: "Russisch",
        en: "English",
        en_source: "Englisch",
        es: "Español",
        es_source: "Spanisch",
        fr: "Français",
        fr_source: "Französisch",
        de: "Deutsch",
        de_source: "Deutsch",
        zh: "中文",
        zh_source: "Chinesisch",
        select_language: "Sprache auswählen",

    },
    zh: {
        update_app: "您需要更新 Telegram 才能继续使用 coob.app",
        select_language_app: "选择应用程序的语言",
        completed: "完成！",
        continue: "继续前进",
        profile: "我的资料",
        assignments: "任务",
        payments: "付款",
        lessons: "课程",
        dashboard: "主页",
        progress: "进度",
        edit_profile: "更新资料",
        attempts: "尝试次数",
        comments: "反馈",
        created: "已创建",
        attempt: "尝试",
        start: "开始",
        no_notifications: "暂无通知。",
        no_assignments: "暂无任务。",
        status: "状态",
        notifications: "通知",
        date: "日期",
        no_payments: "暂无已完成付款 ⭐",
        current_step_locked: "完成此步骤以解锁下一个。",
        step_locked: "完成前面的步骤以解锁内容。",
        reset_progress: "重置进度",
        reset_confirm_message: "您确定要重置进度吗？此操作无法撤销。",
        courses: "最近创建的课程",
        add_course: "添加课程",
        create_course: "创建课程",
        no_courses: "您尚未创建任何课程。",
        create_course_header: "创建课程，分享您的知识",
        delete_course_confirmation: "删除课程",
        delete_course_warning: "您确定要删除此课程吗？此操作无法撤销。",
        delete_step_warning: "您确定要删除此步骤吗？此操作无法撤销。",
        delete_component_warning: "您确定要删除此组件吗？此操作无法撤销。",
        cancel: "取消",
        delete: "删除",
        save: "保存",
        publish_course: "发布课程",
        available_bots: "可用的 Telegram 机器人",
        description: "描述",
        title: "标题",
        empty: "无",
        choose_preset: "选择预设",
        choose_image: "选择图片",
        upload_cover_image: "上传封面图片",
        upload: "上传",
        generate: "生成",
        edit: "编辑",
        draft: "草稿",
        invoice: "发票",
        public: "公开",
        image: "图片",
        text: "文本",
        publish_step: "发布步骤",
        amount: "金额（星星）",
        edit_step: "编辑步骤",
        edit_course: "编辑课程",
        courses_br: "课程",
        Text: "文本",
        Image: "图片",
        Video: "视频",
        Document: "文档",
        Code: "代码",
        Audio: "音频",
        Checkbox: "复选框",
        Painter: "绘图",
        Matcher: "匹配",
        Transcriber: "转录",
        Substituter: "替换",
        CodeVisual: "代码可视化",
        Markdown: "Markdown",
        Editor: "编辑器",
        Basic: "基础",
        Simulator: "模拟器",
        System: "系统",
        Assignment: "作业",
        Other: "其他",
        Programming: "编程",
        Codeletix: "Codeletix",
        add_content: "输入文本并按 Enter ...",
        add_content_empty: "在此添加课程内容 ...",
        success_popup_title: "成功！",
        error_popup_title: "错误！",
        warning_popup_title: "警告！",
        info_popup_title: "信息！",
        close: "关闭",
        add_image: "图片",
        add_text: "文本",
        add_invoice: "发票",
        content: "内容",
        approved: "已批准",
        rejected: "已拒绝",
        pending: "待处理",
        pending_assignments: "待处理任务",
        all_assignments: "所有任务",
        assignment_preview: "作业预览",
        assignment_comment: "答案评论",
        actions: "操作",
        review_assignment_actions: "审核作业操作",
        approve: "批准",
        decline: "拒绝",
        comment_required: "需要填写评论",
        assignment: "作业",
        reply: "回复",
        send: "发送",
        courses_menu: "课程",
        assignments_menu: "任务",
        notifications_menu: "通知",
        payments_menu: "付款",
        profile_menu: "个人资料",
        bots_menu: "机器人",
        let_feedback: "留下评论（可选）",
        subscription_now: "订阅",
        subscription_is_active: "订阅已激活",
        in_month: "每月",
        price: "价格",
        no_plan_found: "未找到订阅计划",
        invoice_step: "发票步骤",
        assignment_plugin: "作业插件",
        export_course: "导出课程",
        student_statistics: "学生统计",
        course_statistics: "课程统计",
        custom_plugin: "自定义插件",
        ai_generator: "AI 生成器",
        yes: "是",
        no: "否",
        standard: "订阅计划：标准",
        unlimited: "订阅计划：无限",
        premium: "订阅计划：高级",
        create_bot: "创建机器人",
        creating_courses: "创建课程",
        subscription_end_date: "订阅截止日期",
        payment_success: "付款成功",
        constructor: "构造器",
        statistics: "统计",
        students: "学生",
        bots: "机器人",
        no_bots: "暂无机器人。",
        set_public: "设为公开",
        course_published: "课程已发布",
        publish_course_header: "准备发布您的课程并绑定到机器人吗？",
        not_publish_course_header: "您确定要取消发布此课程吗？",
        bot_select_header: "选择一个机器人进行发布",
        no_bots_message: "您还没有任何 Telegram 机器人 🙁",
        follow_steps_to_create_bot: "按照以下步骤创建新机器人：",
        step_1_find_botfather: "步骤 1：打开 Telegram 并搜索 @BotFather",
        open_telegram_search_botfather: "打开 Telegram 并搜索 @BotFather。",
        select_verified_botfather: "在搜索栏中输入 @BotFather，并选择带有蓝色认证标志的官方账号。",
        step_2_create_new_bot: "步骤 2：创建新机器人",
        send_newbot_command: "向 @BotFather 发送 /newbot 命令。",
        choose_bot_name: "@BotFather 会要求您为机器人选择一个名称，例如“MyLearningBot”。",
        create_bot_username: "接下来，您需要为机器人创建一个用户名。用户名必须以 'bot' 结尾（例如 mylearning_bot）。",
        step_3_register_bot: "步骤 3：在 coob.app 注册您的机器人",
        forward_api_code: "将 @BotFather 发送的 API 代码转发给 Telegram 中的 @coob_app_bot。",
        bot_ready_message: "恭喜！您的机器人已准备好在 coob 项目中使用 🎉",
        select_bot_message: "选择或创建一个机器人来发布您的课程。",
        no_students: "您还没有任何学生。",
        no_more_students: "...",
        course_progress: "课程进度",
        course_title: "课程标题",
        coming_soon: "此部分即将上线",
        plugin_manager: "安装更多组件",
        marketplace: "市场",
        installed: "已安装",
        my_components: "我的组件",
        components_manager: "组件管理器",
        search_components: "搜索组件...",
        back: "返回",
        develop_plugin_title: "您可以为您的课程开发插件。",
        develop_plugin_description: "请参考快速入门指南，为您的课程创建自定义插件。",
        develop_plugin_link_text: "查看文档",
        by_courses: "课程",
        filter_by_course: "按课程筛选",
        all_courses: "全部",
        clear_filter: "清除筛选",
        enrolled_courses: "已加入的课程",
        username_f: "用户名",
        id_f: "ID",
        students_br: "学生",
        no_transactions: "暂无交易记录。",
        transactions: "交易",
        all: "全部",
        incoming: "收入",
        refund: "退款",
        free: "免费试用！",
        month: "月",
        activate_free_plan: "试用免费计划",
        content_alert_title: "提示",
        content_alert_body: "如果您觉得在应用程序中编辑课程不方便，建议使用桌面版。",
        quote: "引用",
        subscription_info: "订阅信息",
        teacher: "教师",
        student: "学生",
        subscription_is_inactive: "订阅未激活",
        news: "新闻",
        support: "支持",
        documentations: "文档",
        faq: "常见问题",
        subscription_block_title: "订阅",
        other_block_title: "信息",
        welcome_title: "👋 你好，%s！",
        ok_button: "OK",
        welcome_message: "我们已自动检测您的语言。如果有误，您可以立即更改，或稍后在个人资料设置中修改。😉",
        examples: "课程示例",
        example_sh: "示例",
        language: "语言",
        current_language: "当前语言",
        ru: "Русский",
        ru_source: "俄语",
        en: "English",
        en_source: "英语",
        es: "Español",
        es_source: "西班牙语",
        fr: "Français",
        fr_source: "法语",
        de: "Deutsch",
        de_source: "德语",
        zh: "中文",
        zh_source: "中文",
        select_language: "选择语言",
    }
}

export function translate(languageCode, key) {
    if (!translations[languageCode]?.[key]) {
        return key;
    }

    return translations[languageCode]?.[key] || translations.en[key];
}