import React, {useEffect, useState, lazy, Suspense, useCallback, memo} from 'react';
import { IconButton, Divider, Menu, ListItemIcon, MenuItem,  Avatar, Box, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {useNavigate, useParams} from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {setAddedDirection, setSelectedCourse, setComponents, setPlugins, setSelectedStep} from '../../store';
import { styled } from '@mui/material/styles';
import EditWrapperComponent from './EditWrapper';
import AddWrapperComponent from './AddWrapper';
import PluginSkeleton from './PluginSkeleton';
import useWebApp from '../../twa/useWebApp';
import {Breadcrumbs} from "@telegram-apps/telegram-ui";
import {translate} from "../../translations";
import {truncateText} from "../../helper";
import Upsert from "./Upsert";
import ContentEditable from "./ContentEditable";
import ExtensionIcon from '@mui/icons-material/Extension';
import PluginManagerMobile from "./PluginManagerMobile";
import DesktopEditTip from "../../DesktopEditTip";

const Plugin = lazy(() => import('./Plugin'));

const AddContentText = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Центрирование по горизонтали
    justifyContent: 'center', // Центрирование по вертикали
    textAlign: 'center',
    color: theme.palette.text.disabled,
    padding: theme.spacing(5),
    fontSize: '20px',
    paddingTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
}));

const LoadingContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    paddingTop: theme.spacing(2.5),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(2.5),
}));

const EditWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    '&:hover $addButton': {
        visibility: 'visible',
    },
}));

const AddWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    padding: theme.spacing(2),
    '&:hover $addButton': {
        visibility: 'visible',
    },
}));


const AddButton = styled(({ showFlag, ...other }) => <IconButton {...other} />)(({ theme, showFlag }) => ({
    position: 'absolute',
    top: theme.spacing(-2.5),
    left: '50%',
    transform: 'translateX(-50%)',
    visibility: showFlag ? 'visible' : 'hidden',
}));

const AlwaysAddButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(-2),
    left: '50%',
    transform: 'translateX(-50%)',
    visibility: 'visible',
}));

const MainContainer = styled(Box)(({ theme, themeParams }) => ({
    width: 'calc(100% - 20px)',
    marginLeft: '10px',
    marginRight: '15px',
    marginTop:'20px',
    marginBottom: '50px',
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: themeParams.section_bg_color,
    boxShadow: theme.shadows[4],
}));

const StepContent = ({ themeParams, language, dark }) => {
    const { courseId, stepId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const accessToken = useSelector(state => state.accessToken);
    const components = useSelector(state => state.components);
    const selectedStep = useSelector(state => state.selectedStep);
    const selectedCourse = useSelector(state => state.selectedCourse);
    const plugins = useSelector(state => state.plugins);
    const [isLoading, setIsLoading] = useState(true);
    const [pluginLoading, setPluginLoading] = useState(true);
    const [isLastPlugin, setIsLastPlugin] = useState(false);
    const [loading, setLoading] = useState(true);
    const selectedComponent = useSelector((state) => state.selectedComponent);
    const [anchorEl, setAnchorEl] = useState(null);
    const ITEM_HEIGHT = 85;
    const API_URL = 'https://api.coob.app'
    const [isPluginManagerOpen, setPluginManagerOpen] = useState(false);

    let WebApp = useWebApp();
    const colorScheme = WebApp.colorScheme || 'light';

    useEffect(() => {
        dispatch(setAddedDirection(null));
    }, []);

    const handleBackClick = useCallback(() => {
        navigate(`/`);
        if (WebApp) {
            WebApp.BackButton.hide();
            WebApp.MainButton.hide();
        }
    }, [WebApp]);

    const handleCardStepClick = useCallback(() => {
        navigate(`/courses/${selectedCourse.id}`);
    });

    const handleMenuOpen = (event, isLastPlugin) => {
        setAnchorEl(event.currentTarget);
        setIsLastPlugin(isLastPlugin);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setIsLastPlugin(false);
    };

    const addPluginToComponent = (plugin) => {
        dispatch(setAddedDirection({
            id: plugin.id,
            type: 'add',
            name: plugin.name,
            description: plugin.description,
            version: plugin.version,
            payload: {
                edit: `${API_URL}/v1/plugins/${plugin.id}/edit-body?token=Bearer ${accessToken}`,
            },
            sortIndex: -100,
            isLastPlugin: isLastPlugin,
        }));

        handleMenuClose();
    };

    // openPluginManager
    const openPluginManager = () => {
        setPluginManagerOpen(true);
        handleMenuClose();
    }

    const handleClosePluginManager = () => setPluginManagerOpen(false);


    const groupedPlugins = plugins.reduce((acc, plugin) => {
        if (!acc[plugin.category]) {
            acc[plugin.category] = [];
        }
        acc[plugin.category].push(plugin);
        return acc;
    }, {});

    Object.keys(groupedPlugins).forEach((category) => {
        groupedPlugins[category].sort((a, b) => a.sort_index - b.sort_index);
    });

    useEffect(() => {
        const fetchCourse = async () => {
            try {
                const response = await axios.get(`/v1/courses/${courseId}`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                });
                dispatch(setSelectedCourse(response.data.data));
            } catch (error) {
                console.error('Error fetching course details:', error);
            } finally {
                setLoading(false);
            }
        };

        const fetchStep = async () => {
            try {
                const response = await axios.get(`/v1/steps/${stepId}`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                });
                const step = response.data.data;
                dispatch(setSelectedStep({ id: step.id, type: 'step', entity: step }));
            } catch (error) {
                console.error('Error fetching step details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCourse();
        fetchStep();
    }, [courseId, stepId, accessToken]);

    useEffect(() => {
        if (WebApp) WebApp.BackButton.show();
        if (WebApp) {
            WebApp.BackButton.onClick(handleCardStepClick);
        }
    }, [WebApp, handleCardStepClick]);

    useEffect(() => {
        const fetchComponents = async () => {
            try {
                const { data } = await axios.get(`/v1/steps/${stepId}/components?withEdit=true`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                dispatch(setComponents(data.data));
            } catch (error) {
                console.error('Error fetching components:', error);
            } finally {
                setIsLoading(false);
            }
        };

        const fetchPlugins = async () => {
            setPluginLoading(true);
            try {
                const { data } = await axios.get(`/v1/plugins?course_id=${courseId}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                dispatch(setPlugins(data.data));
            } catch (error) {
                console.error('Error fetching plugins:', error);
            } finally {
                setPluginLoading(false);
            }
        };

        fetchPlugins();
        fetchComponents();
    }, [courseId, stepId, dispatch, accessToken]);

    return (
        <Box>
            <Box sx={{
                padding: '20px',
                backgroundColor: themeParams.background_color,
                color: themeParams.text_color
            }}>
                <Breadcrumbs divider="chevron">
                    <Box onClick={handleBackClick} sx={{
                        fontSize: '0.80rem',
                        marginRight: '10px',
                        color: themeParams.section_header_text_color,
                    }}>
                        {translate(language, 'courses_br')}
                    </Box>
                    {!loading && (
                            <Box onClick={handleCardStepClick} sx={{
                                fontSize: '0.80rem',
                                marginRight: '10px',
                                marginLeft: '10px',
                                color: themeParams.section_header_text_color,
                            }}>
                                {truncateText(selectedCourse ? selectedCourse.title: '', 15)}
                            </Box>
                    )}
                    {!loading && (
                        <Box sx={{
                            fontSize: '0.80rem',
                            marginLeft: '10px',
                            color: themeParams.section_header_text_color,
                        }}>
                            {truncateText(selectedStep ? selectedStep.entity.name: '', 15)}
                        </Box>
                    )}
                </Breadcrumbs>
            </Box>
            <DesktopEditTip language={language} themeParams={themeParams} />
            <MainContainer themeParams={themeParams}>
            {(!isLoading && components && components.length === 0) && (
                <AddContentText>
                    <img src="https://coob.app/assets/images/rbtn.png" alt="empty" style={{
                        borderRadius: '20px', // Закругление углов
                        padding: 10, // Отступы
                        height: 250, // Высота
                        opacity: 0.8, // Прозрачность
                    }} />
                    {translate(language, 'add_content_empty')}
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{
                        marginTop: '10px',
                        color: `${themeParams.button_color}`, // Цвет иконки
                    }}>
                        <path d="M12 2L12 22M12 22L5 15M12 22L19 15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </AddContentText>)}
                {isLoading ? (
                    <LoadingContainer>
                        {Array.from({ length: 5 }).map((_, index) => (
                            <PluginSkeleton key={index} />
                        ))}
                    </LoadingContainer>
                ) : (
                    <Suspense
                        fallback={
                            <LoadingContainer>
                                {components.map((_, index) => (
                                    <PluginSkeleton key={index} />
                                ))}
                            </LoadingContainer>
                        }
                    >
                        {components.map((component) => (
                            <EditWrapperComponent key={component.id} component={component} themeParams={themeParams} language={language}>
                                <EditWrapper>
                                    <AddButton onClick={(event) => handleMenuOpen(event, false)} showFlag={component?.id === selectedComponent?.id}  sx={{
                                        position: 'absolute',
                                        backgroundColor: `${themeParams.button_color}AA`, // Прозрачность фона
                                        backgroundImage: 'none',
                                        backdropFilter: 'blur(5px)', // Эффект размытия
                                        '&:hover': {
                                            backgroundColor: `${themeParams.button_color}CC`, // Прозрачность при наведении
                                        },
                                    }}>
                                        <AddIcon />
                                    </AddButton>
                                    <Plugin component={component} isSyllabus={true} viewerWidthPercent={93} themeParams={themeParams} language={language}  mode={dark ? 'dark' : 'light'} />
                                </EditWrapper>
                            </EditWrapperComponent>
                        ))}
                    </Suspense>
                )}

                {!isLoading && (
                    <>
                        <AddWrapperComponent key={"add_ec"} themeParams={themeParams} language={language}>
                            <AddWrapper>
                                <AddButton onClick={(event) => handleMenuOpen(event, true)} showFlag={true}  sx={{
                                    position: 'absolute',
                                    backgroundColor: `${themeParams.secondary_bg_color}AA`,
                                    backgroundImage: 'none',
                                    backdropFilter: 'blur(5px)',
                                    '&:hover': {
                                        backgroundColor: `${themeParams.secondary_bg_color}CC`,
                                    },
                                }}>
                                    <AddIcon />
                                </AddButton>
                                <ContentEditable
                                    disabled={false}
                                    focus={false}
                                    placeholder={translate(language, 'add_content')}
                                />
                            </AddWrapper>
                        </AddWrapperComponent>
                    </>
                )}
            </MainContainer>

            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '90%',
                        maxWidth: '500px',
                        backgroundColor: themeParams.section_bg_color,
                        borderRadius: '20px',
                    },
                }}
            >
                {Object.entries(groupedPlugins).flatMap(([category, pluginsInCategory]) => [
                    <Typography key={category} variant="h6" sx={{
                        paddingX: 2, paddingY: 1,
                        style: 'bold',
                        fontSize: '12px',
                        paddingLeft: '20px',
                        textTransform: 'uppercase',
                        color: themeParams.section_header_text_color,
                    }}>{translate(language, category)}</Typography>,
                    <Divider key={category + "-divider"} />,
                    pluginsInCategory.map((plugin) => (
                        <MenuItem  key={plugin.id} onClick={() => addPluginToComponent(plugin)}>
                            <ListItemIcon sx={{
                                marginRight: 2,
                                color: themeParams.section_header_text_color,
                            }}>
                                <Avatar src={plugin.icon} alt={plugin.name} sx={{
                                    backgroundColor: '#fff',
                                }}>
                                    {plugin.name.charAt(0)}
                                </Avatar>
                            </ListItemIcon>
                            <Box>
                                <Typography variant="body1" sx={{
                                    color: themeParams.text_color,
                                    textWeight: 'bold',
                                }}>{translate(language, plugin.name)}</Typography>
                                <Typography variant="body2" sx={{
                                    color: themeParams.subtitle_text_color,
                                }}>{plugin.short_description}</Typography>
                            </Box>
                        </MenuItem>
                    ))
                ])}
                <Divider />
                <MenuItem onClick={openPluginManager} className="plugin-manager-menu-item">
                    <ListItemIcon>
                        <ExtensionIcon />
                    </ListItemIcon>
                    <Typography variant="inherit" style={{ fontWeight: 'bold' }}>
                        {translate(language, 'plugin_manager')}
                    </Typography>
                </MenuItem>
            </Menu>
            <PluginManagerMobile
                language={language}
                themeParams={themeParams}
                open={isPluginManagerOpen}
                onClose={handleClosePluginManager}
                course_id={courseId}
            />
            <Upsert
                isSyllabus={true}
                viewerWidthPercent={93}
                themeParams={themeParams}
                language={language}
                mode={dark ? 'dark' : 'light'}  />
        </Box>
    );
};

export default memo(StepContent);