import React, { useEffect, useState } from 'react';

const CoverGenerator = ({
                            preset = '',
                            text = 'Course Title',
                            fontSize = '32px',
                            fontColor = '#000000',
                            bgColor = '#ffffff',
                            borderColor = '#000000',
                            shadow = 'off',
                            fontFamily = 'Roboto',
                            borderWidth = '2px',
                            backgroundGradientTemplate = '',
                            width = 520,
                        }) => {
    const [data, setData] = useState({ presets: {}, gradients: {} });
    const [finalParams, setFinalParams] = useState({
        fontSize,
        fontColor,
        fontFamily,
        bgColor,
        borderColor,
        borderWidth,
        shadow,
        backgroundGradientTemplate,
        text,
    });

    // Загружаем данные с API
    useEffect(() => {
        fetch('https://api.coob.app/public/cj')
            .then(response => response.json())
            .then(json => {
                setData(json);

                // Если передан `preset`, обновляем `finalParams`
                if (preset && json.presets[preset]) {
                    setFinalParams(prev => ({
                        ...prev,
                        ...json.presets[preset],
                    }));
                }
            })
            .catch(error => console.error('Error fetching cover data:', error));
    }, [preset]);

    // Применяем градиент, когда данные загружены
    const gradient = data.gradients[finalParams.backgroundGradientTemplate] || {};

    return (
        <div
            id="cover"
            style={{
                width: '100%',
                maxWidth: '520px',
                height: 'auto',
                aspectRatio: '520 / 315',
                border: `${finalParams.borderWidth} solid ${finalParams.borderColor}`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                fontFamily: finalParams.fontFamily,
                backgroundColor: gradient.backgroundColor || finalParams.bgColor,
                backgroundImage: gradient.backgroundImage || '',
                position: 'relative',
            }}
        >
            <h1
                id="cover-text"
                style={{
                    margin: 0,
                    padding: 0,
                    color: finalParams.fontColor,
                    fontSize: width < 520
                        ? `calc(${parseFloat(finalParams.fontSize)}px * (100% / ${width}px))`
                        : finalParams.fontSize,
                    textShadow: finalParams.shadow === 'on' ? '2px 2px 5px rgba(0, 0, 0, 0.5)' : 'none',
                }}
            >
                {finalParams.text}
            </h1>
        </div>
    );
};

export default CoverGenerator;